<!--
  功能：功能描述
  作者：Maoxiangdong
  邮箱：862755519@qq.com
  时间：2021年10月08日 15:13:16
-->
<template>
<div>
    <Pointwave></Pointwave>
    <div class="container">
        <Row>
            <Col :xs="0" :sm="0" :md="18" :lg="18">
            <div class="login-left">
                <div class="headline">腾冲市乡村振兴信息管理管理平台</div>
                <div class="subhead">智慧城建，为产业化赋能</div>
            </div>
            </Col>
            <Col :xs="24" :sm="24" :md="6" :lg="6">
            <div class="login-card">
                <div class="login-title">欢迎登录</div>
                <Form>
                    <FormItem>
                        <Input prefix="ios-contact" placeholder="请输入账号" />
                    </FormItem>
                    <FormItem>
                        <Input prefix="md-lock" type="password" placeholder="请输入账号" />
                    </FormItem>
                    <FormItem>
                        <div class="form-row">
                            <Checkbox>记住密码</Checkbox>
                            <span style="color: #2d8cf0">没有账号？</span>
                        </div>
                    </FormItem>
                    <FormItem>
                        <Button type="primary" long @click="login">登录</Button>
                    </FormItem>
                    <FormItem>
                        <Divider style="font-size: 14px">其他方式登录</Divider>
                        <div class="svg-box">
                            <div class="svg-item">
                                <img src="@/assets/images/wx.svg" alt="" srcset="" />
                                <div>微信</div>
                            </div>
                            <div class="svg-item">
                                <img src="@/assets/images/qq.svg" alt="" srcset="" />
                                <div>QQ</div>
                            </div>
                            <div class="svg-item">
                                <img src="@/assets/images/github.svg" alt="" srcset="" />
                                <div>GitHub</div>
                            </div>
                        </div>
                    </FormItem>
                </Form>
            </div>
            </Col>
        </Row>
    </div>
</div>
</template>

<script>
import Pointwave from "@/components/Pointwave/index.vue";
export default {
    // 组件名称
    name: "demo",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 局部注册的组件
    components: {
        Pointwave,
    },
    // 组件状态值
    data() {
        return {};
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    methods: {
        login() {
            this.$router.push("/visualization");
        },
    },
    // 以下是生命周期钩子   注：没用到的钩子请自行删除
    /**
     * 在实例初始化之后，组件属性计算之前，如data属性等
     */
    beforeCreate() {},
    /**
     * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
     */
    created() {
        this.$nextTick(() => {
            // 禁用右键

            document.oncontextmenu = new Function("event.returnValue=false");
            // 禁用选择
            document.onselectstart = new Function("event.returnValue=false");
        });
    },
    /**
     * 在挂载开始之前被调用：相关的 render 函数首次被调用。
     */
    beforeMount() {},
    /**
     * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
     * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
     */
    mounted() {},
    /**
     * 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。
     * 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
     */
    beforeUpdate() {},
    /**
     * 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
     * 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
     */
    updated() {},
    /**
     * keep-alive 组件激活时调用。 仅针对keep-alive 组件有效
     */
    activated() {},
    /**
     * keep-alive 组件停用时调用。 仅针对keep-alive 组件有效
     */
    deactivated() {},
    /**
     * 实例销毁之前调用。在这一步，实例仍然完全可用。
     */
    beforeDestroy() {},
    /**
     * Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，
     * 所有的事件监听器会被移除，所有的子实例也会被销毁。
     */
    destroyed() {},
};
</script> 

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--使用了scoped属性之后，父组件的style样式将不会渗透到子组件中，-->
<!--然而子组件的根节点元素会同时被设置了scoped的父css样式和设置了scoped的子css样式影响，-->
<!--这么设计的目的是父组件可以对子组件根元素进行布局。-->

<style lang="scss" scoped>
.demo {
    .demo-title {
        padding: 5px 10px;
        font-size: 14px;
        color: #333;
        border-bottom: 1px solid #e5e6e9;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .demo-row {
        display: flex;
        padding: 10px;

        img {
            width: 140px;
            height: 112px;
            border-radius: 3px;
        }

        .demo-text {
            // border: 1px solid red;
            width: 130px;
            margin-left: 10px;

            p {
                font-size: 13px;
                color: #333;
            }

            div {
                font-size: 12px;
                color: #3cbbb0;
            }
        }
    }
}

.container {
    z-index: 1000;
    position: absolute;
    width: 100%;
    height: 100vh;
}

.login-card {
    background: #ffffff;
    height: 100vh;
    padding: 30px;

    .login-title {
        text-align: center;
        color: #000;
        font-size: 23px;
        margin-bottom: 40px;
        letter-spacing: 5px;
        margin-top: 100px;
    }

    .form-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .svg-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .svg-item {
        width: 33%;
        text-align: center;
        padding: 0px;

        img {
            width: 35px;
            height: 35px;
        }

        div {
            font-size: 14px;
        }
    }
}

.login-left {
    padding: 100px;
    height: 100vh;

    .headline {
        color: #f5f7f9;
        font-size: 35px;
        letter-spacing: 5px;
        margin-top: 100px;
        font-weight: 500;
    }

    .subhead {
        color: #ffffff;
        font-size: 22px;
        letter-spacing: 5px;
        margin-top: 15px;
        font-weight: 500;
    }
}
</style>
