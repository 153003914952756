<!--
  功能：功能描述
  作者：Maoxiangdong
  邮箱：862755519@qq.com
  时间：2021年10月08日 15:06:25
-->
<template>
<div id="indexLizi" />
</template>

<script>
//导入three粒子包
import * as THREE from 'three'
export default {
    // 组件名称
    name: 'Pointwave',
    // 组件参数 接收来自父组件的数据
    props: {
        amountX: {
            type: Number,
            default: 50
        },
        amountY: {
            type: Number,
            default: 50
        },
        color: {
            type: Number,
            default: 0xffffff
        },
        top: {
            type: Number,
            default: 0
        }
    },
    // 局部注册的组件
    components: {},
    // 组件状态值
    data() {
        return {
            count: 0,
            // 用来跟踪鼠标水平位置
            mouseX: 0,
            windowHalfX: null,
            // 相机
            camera: null,
            // 场景
            scene: null,
            // 批量管理粒子
            particles: null,
            // 渲染器
            renderer: null
        }
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    methods: {
        init: function () {
            const SEPARATION = 100
            const SCREEN_WIDTH = window.innerWidth
            const SCREEN_HEIGHT = window.innerHeight
            const container = document.createElement('div')
            this.windowHalfX = window.innerWidth / 2
            container.style.position = 'relative'
            container.style.top = `${this.top}px`
            container.style.height = `${(SCREEN_HEIGHT - this.top)}px`
            document.getElementById('indexLizi').appendChild(container)

            this.camera = new THREE.PerspectiveCamera(75, SCREEN_WIDTH / SCREEN_HEIGHT, 1, 10000)
            this.camera.position.z = 1000

            this.scene = new THREE.Scene()

            const numParticles = this.amountX * this.amountY
            const positions = new Float32Array(numParticles * 3)
            const scales = new Float32Array(numParticles)
            // 初始化粒子位置和大小
            let i = 0
            let j = 0
            for (let ix = 0; ix < this.amountX; ix++) {
                for (let iy = 0; iy < this.amountY; iy++) {
                    positions[i] = ix * SEPARATION - ((this.amountX * SEPARATION) / 2)
                    positions[i + 1] = 0
                    positions[i + 2] = iy * SEPARATION - ((this.amountY * SEPARATION) / 2)
                    scales[j] = 1
                    i += 3
                    j++
                }
            }

            const geometry = new THREE.BufferGeometry()
            geometry.addAttribute('position', new THREE.BufferAttribute(positions, 3))
            geometry.addAttribute('scale', new THREE.BufferAttribute(scales, 1))
            // 初始化粒子材质
            const material = new THREE.ShaderMaterial({
                uniforms: {
                    color: {
                        value: new THREE.Color(this.color)
                    }
                },
                vertexShader: `
          attribute float scale;
          void main() {
            vec4 mvPosition = modelViewMatrix * vec4( position, 2.0 );
            gl_PointSize = scale * ( 300.0 / - mvPosition.z );
            gl_Position = projectionMatrix * mvPosition;
          }
        `,
                fragmentShader: `
          uniform vec3 color;
          void main() {
            if ( length( gl_PointCoord - vec2( 0.5, 0.5 ) ) > 0.475 ) discard;
            gl_FragColor = vec4( color, 1.0 );
          }
        `
            })

            this.particles = new THREE.Points(geometry, material)
            this.scene.add(this.particles)

            this.renderer = new THREE.WebGLRenderer({
                antialias: true,
                alpha: true
            })
            this.renderer.setSize(container.clientWidth, container.clientHeight)
            this.renderer.setPixelRatio(window.devicePixelRatio)
            this.renderer.setClearAlpha(0)
            container.appendChild(this.renderer.domElement)

            window.addEventListener('resize', this.onWindowResize, {
                passive: false
            })
            document.addEventListener('mousemove', this.onDocumentMouseMove, {
                passive: false
            })
            document.addEventListener('touchstart', this.onDocumentTouchStart, {
                passive: false
            })
            document.addEventListener('touchmove', this.onDocumentTouchMove, {
                passive: false
            })
        },
        render: function () {
            this.camera.position.x += (this.mouseX - this.camera.position.x) * 0.05
            this.camera.position.y = 400
            this.camera.lookAt(this.scene.position)
            const positions = this.particles.geometry.attributes.position.array
            const scales = this.particles.geometry.attributes.scale.array
            // 计算粒子位置及大小
            let i = 0
            let j = 0
            for (let ix = 0; ix < this.amountX; ix++) {
                for (let iy = 0; iy < this.amountY; iy++) {
                    positions[i + 1] = (Math.sin((ix + this.count) * 0.3) * 100) + (Math.sin((iy + this.count) * 0.5) * 100)
                    scales[j] = (Math.sin((ix + this.count) * 0.3) + 1) * 8 + (Math.sin((iy + this.count) * 0.5) + 1) * 8
                    i += 3
                    j++
                }
            }
            // 重新渲染粒子
            this.particles.geometry.attributes.position.needsUpdate = true
            this.particles.geometry.attributes.scale.needsUpdate = true
            this.renderer.render(this.scene, this.camera)
            this.count += 0.1
        },
        animate: function () {
            requestAnimationFrame(this.animate)
            this.render()
        },
        onDocumentMouseMove: function (event) {
            this.mouseX = event.clientX - this.windowHalfX
        },
        onDocumentTouchStart: function (event) {
            if (event.touches.length === 1) {
                this.mouseX = event.touches[0].pageX - this.windowHalfX
            }
        },
        onDocumentTouchMove: function (event) {
            if (event.touches.length === 1) {
                event.preventDefault()
                this.mouseX = event.touches[0].pageX - this.windowHalfX
            }
        },
        onWindowResize: function () {
            this.windowHalfX = window.innerWidth / 2
            this.camera.aspect = window.innerWidth / window.innerHeight
            this.camera.updateProjectionMatrix()
            this.renderer.setSize(window.innerWidth, window.innerHeight)
        }
    },
    // 以下是生命周期钩子   注：没用到的钩子请自行删除
    /**
     * 在实例初始化之后，组件属性计算之前，如data属性等
     */
    beforeCreate() {},
    /**
     * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
     */
    created() {},
    /**
     * 在挂载开始之前被调用：相关的 render 函数首次被调用。
     */
    beforeMount() {},
    /**
     * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
     * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
     */
    mounted() {
        this.init()
        this.animate()
    },
    /**
     * 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。
     * 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
     */
    beforeUpdate() {},
    /**
     * 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
     * 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
     */
    updated() {},
    /**
     * keep-alive 组件激活时调用。 仅针对keep-alive 组件有效
     */
    activated() {},
    /**
     * keep-alive 组件停用时调用。 仅针对keep-alive 组件有效
     */
    deactivated() {},
    /**
     * 实例销毁之前调用。在这一步，实例仍然完全可用。
     */
    beforeDestroy() {},
    /**
     * Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，
     * 所有的事件监听器会被移除，所有的子实例也会被销毁。
     */
    destroyed() {}
}
</script> 

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--使用了scoped属性之后，父组件的style样式将不会渗透到子组件中，-->
<!--然而子组件的根节点元素会同时被设置了scoped的父css样式和设置了scoped的子css样式影响，-->
<!--这么设计的目的是父组件可以对子组件根元素进行布局。-->

<style lang="scss" scoped>
#indexLizi {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    background-image: url("../../assets/images/login_bg.jpg");
    background-size: 100vw 100vh;
}
</style>
